<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 3 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 3: Homeowner Info
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_1"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #1 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_2"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #2 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Revision #3 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_4"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="this.selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="this.selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Projected Closing Date"
                                    color="black"
                                    type="date"
                                    v-model="this.selected_user.BuildEnd"
                                />
                            </v-col>

                          <v-col col="12" md="12">
                                <v-header><strong>Additional Information/Notes</strong><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_18"/></v-header>
                            </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 3: Exterior
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12">
                              <v-header><strong>OUTSIDE</strong> WATER SPIGOT:  (2) LOCATIONS TO BE DETERMINED BY PLUMBER (SIDE OF HOUSE)</v-header>
                            </v-col>
                            <v-col cols="12">
                              <v-header><strong>OUTSIDE</strong> ELECTRICAL OUTLETS (2) LOCATIONS-NEXT TO DOORS (UNLESS SPECIFIED, WILL BE INSTALLED SAME AS MODEL)</v-header>
                            </v-col>
                            <v-col cols="12">
                                <v-header>*1’ IRRIGATION PIPE R.I. (Replacing 1 STD. water spigot or adding line  ${{this.price_section_three.column_11}})</v-header>
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6"><v-header><strong>DECK</strong> BOARD (12’ LENGTH STD) ADDITIONAL LENGTH COST IS ${{this.price_section_three.column_12}} PER FOOT. </v-header></v-col>
                            <v-col cols="12" md="3"><v-text-field :readonly="permissions === 'read:homeowner'"/></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="1"><v-subheader>DECK:</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No', 'Undecided']" v-model="section.column_19"/></v-col>
                            <v-col cols="12" md="2"><v-subheader>DECK SIZE:</v-subheader></v-col>
                            <v-col cols="12" md="6"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_20"/></v-col>
                      </v-row>
                      <v-row>
                            <v-col cols="12" md="2"><v-subheader>MATERIALS OF DECK:</v-subheader></v-col>
                            <v-col cols="12" md="4"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_21"/></v-col>
                            <v-col cols="12" md="1"><v-subheader>STEPS:</v-subheader></v-col>
                            <v-col cols="12" md="5"><v-select :readonly="permissions === 'read:homeowner'" :items="['FULL','HALF','Undecided']" v-model="section.column_22"/></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4"><v-subheader>MATERIALS FOR BALISTER (STD. WOOD):</v-subheader></v-col>
                            <v-col cols="12" md="8" ><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_23"/></v-col>
                            <v-col cols="12" ><v-subheader>PATIO (FOR WALKOUT HOUSE PLANS - STD. 6’ X 6’) LOCATION & SIZE TO BE DETERMINED AT ELECTRICAL WALK THROUGH.</v-subheader></v-col>
                          <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-textarea :readonly="permissions === 'read:homeowner'" v-model="section.column_24"/></v-header>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="2"><v-header>CONFIRM GARAGE</v-header></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Left','Right', 'Determined by Development','Undecided']" v-model="section.column_25"/></v-col>
                            <v-col cols="12" md="4"><v-subheader>LOCATION OF HOUSE ON LOT: (Centered if not specified)</v-subheader></v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_5"/></v-col>
                        </v-row>
                          <v-row>
                            <v-col cols="12" ><v-header>**PLEASE NOTE: BLUEPRINT MAY BE DRAWN IN REVERSE</v-header></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="1"><v-subheader>DRIVEWAY:</v-subheader></v-col>
                            <v-col cols="12" md="2"><v-select :readonly="permissions === 'read:homeowner'" :items="['Standard Asphalt','Concrete (Bid Only)','Undecided']" v-model="section.column_26"/></v-col>
                            <v-col cols="12" md="2"><v-subheader>DRIVEWAY WIDTH:</v-subheader></v-col>
                            <v-col cols="12" md="4"><v-select :readonly="permissions === 'read:homeowner'" :items="['Standard', 'Optional:(Bid Only-IF CITY ALLOWS','Undecided']" v-model="section.column_27"/></v-col>
                            <v-col cols="12" md="2"><v-text-field :readonly="permissions === 'read:homeowner'" label="Optional Size" v-model="section.column_28"/></v-col>
                            </v-row>
                          <v-row>
                            <v-col cols="6" md="6"><v-subheader>GARAGE FLOOR DRAIN (SINGLE ROUND CTR./MAIN STALL ${{this.price_section_three.column_13}}):</v-subheader></v-col>
                              <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No', 'Undecided']" v-model="section.column_29"/></v-col>
                            <v-col cols="12" md="4"><v-subheader>GARAGE APRON (OPTIONAL): (Bid Only):</v-subheader></v-col>
                            <v-col cols="12" md="8"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_30"/></v-col>
                            </v-row>
                          <v-row>
                            <v-col cols="12" md="4"><v-subheader>CONFIRM LANDSCAPE ALLOWANCE:</v-subheader></v-col>
                            <v-col cols="12" md="8"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_31"/></v-col>
                            <v-col cols="12" ><v-subheader>NOTE: IT WILL BE HOMEOWNERS RESPONSIBILITY TO COORDINATE ALL LANDSCAPING/IRRIGATION INSTALLATIONS & PROPER ALLOWANCES NEEDED FOR LOT REQUIREMENT WITH Boulder Contracting - Brian Jansen 612-369-2746)</v-subheader></v-col>
                            <v-col cols="12" md="12"><v-subheader>ITEMS THAT MAY BE DONE BY HOMEOWNER <strong>(SEE NOTICE VIII)</strong>:</v-subheader></v-col>
                            <v-col cols="12" ><v-textarea v-model="section.column_32"></v-textarea></v-col>
                            <v-col cols="12" ><v-subheader>NOTE: IT WILL BE HOMEOWNERS RESPONSIBILITY TO COORDINATE ABOVE ITEMS WITH BoulderContracting - Brian Jansen 612-369-2746</v-subheader></v-col>
                        </v-row>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </v-col>
                      </template> -->
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select :readonly="permissions === 'read:homeowner'"
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                           </v-dialog>
                    </v-container>
                </v-form>
                </base-material-card>
             <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                     v-model="section.column_33"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_34"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                     v-model="section.column_35"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_36"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>
                
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
    name:'Section 3',
    data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      section_id: "3",
      staff_email: 'steve@skelleymarketing.com',
      add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      price_section_three: {
        column_11: '',
        column_12: '',
        column_13: ''
      },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_30:'',
        column_31:'',
        column_32:'',
        column_33:'',
        column_34:'',
        column_35:'',
        column_36:''
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
    }
  },
  created () {
    this.getSection()
    this.getHomePrice()
    this.getVendorEmail()
    this.getUserInfo()
    },
    methods:{

      getSection() {
        axios
        .get(process.env.VUE_APP_GET_SECTION_TWO_URL,
        {
        params: {
            home_id: this.home_id,
            section_id: this.section_id
        }
        })
        .then(response => {
          if (response.data) {
            var result = response.data
            this.section = JSON.parse(result['JsonDetails'])
          }
        })
        .catch(error => {
          console.log(error)
        })
      },
      getUserInfo() {
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
       getHomePrice(){
            axios
            .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.price_section_three = JSON.parse(result['SectionThree']) || {}
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getVendorEmail () {
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,
          {
            // params: {
            // }
          }
          )
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }

            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
      sectionComplete(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }

            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
                this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
            })
            .catch(error => {
            console.log(error)
            })
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
          this.close()
        },
      close () {
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
      sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
    }
}
</script>